import React from 'react'
import img from '../../images/oczyszczanie-wodorowe.webp'
import {Link} from 'gatsby'
import Layout from '../../components/Layout'
import {Fade} from 'react-reveal'
import {Seo} from '../../components/Seo'

const OczyszczanieWodoroweTwarz = () => {
  return (
    <>
      <Layout>
        <Seo
          title='Oczyszczanie Wodorowe | Modelowanie Sylwetki'
          description='Zabieg wodorowego oczyszczania twarzy łączy w sobie kilka elementów:
          oczyszczenie, odżywienie, dotlenienie, rewitalizację, a nawet jest to
          zabieg przeciwstarzeniowy. Dzięki oczyszczaniu wodorowemu uzyskuje się
          efekt skóry idealnie oczyszczonej, odświeżonej, zdrowej i
          zregenerowanej.'
          url='https://modelowaniesylwetki-wawer.pl/zabiegi-na-twarz/oczyszczanie-wodorowe'
        />
        <div className='page-container'>
          <div className='page'>
            <div className='content-1'>
              <h1>Oczyszczanie Wodorowe</h1>
              <div className='section'>
                <div className='image-container'>
                  <img
                    src={img}
                    width='1000px'
                    height='667px'
                    alt='Oczyszczanie wodorowe zdjęcie'
                  ></img>
                </div>
                <div className='text'>
                  <p>
                    Zabieg wodorowego oczyszczania twarzy łączy w sobie kilka elementów:
                    oczyszczenie, odżywienie, dotlenienie, rewitalizację, a nawet jest to
                    zabieg przeciwstarzeniowy. Dzięki oczyszczaniu wodorowemu uzyskuje się
                    efekt skóry idealnie oczyszczonej, odświeżonej, zdrowej i
                    zregenerowanej. Wodorowe oczyszczanie twarzy jest to zabieg o dość
                    szerokim zastosowaniu, zalecany zarówno kobietom, jak i mężczyznom
                    niezależnie od pory roku. Można go stosować do każdego rodzaju i typu
                    cery, nawet przy cerze trądzikowej, wrażliwej lub naczynkowej.
                    Szczególnie zaleca się go dla cery szarej i zmęczonej, o nierównym
                    kolorycie, w przypadku nadmiernego wydzielania sebum, czy przy
                    występowaniu trądziku, zaskórników i rozszerzonych porów.
                  </p>
                  <div className='cennik'>
                    <span>CENNIK</span>
                    <h2>OCZYSZCZANIE WODOROWE TWARZ</h2>
                    <ul>
                      <li>
                        <p>OCZYSZCZANIE WODOROWE - KAWITACJA, WODOROWE, MASKA</p>
                        <span className='prices'>297 zł</span>
                      </li>
                      <li>
                        <p>OCZYSZCZANIE WODOROWE - KAWITACJA, WODOROWE, MASKA, KWAS</p>
                        <span className='prices'>347 zł</span>
                      </li>
                      <li>
                        <p>OCZYSZCZANIE WODOROWE - 6 ETAPÓW, AMPUŁKA</p>
                        <span className='prices'>397 zł</span>
                      </li>
                    </ul>
                    <h2>OCZYSZCZANIE WODOROWE - PLECY LUB DEKOLT</h2>
                    <ul>
                      <li>
                        <p>OCZYSZCZANIE WODOROWE - KAWITACJA, WODOROWE, MASKA</p>
                        <span className='prices'>347 zł</span>
                      </li>
                      <li>
                        <p>OCZYSZCZANIE WODOROWE - KAWITACJA, WODOROWE, MASKA, KWAS</p>
                        <span className='prices'>397 zł</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <Fade left>
              <Link to='/kontakt'>
                <button>Umów Wizytę</button>
              </Link>
            </Fade>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default OczyszczanieWodoroweTwarz
